<template>
  <div class="orderlistView">
    <van-sticky>
      <van-nav-bar title="已完成订单" left-arrow @click-left="onClickLeft" />
      <van-dropdown-menu>
        <van-dropdown-item v-model="value1" :options="option1" @change="onChangeType" />
      </van-dropdown-menu>
      <div class="total">
        <van-button class="date" @click="chooeDate">
          {{ viewformatDate }}
          <van-icon name="play" class="transform" />
        </van-button>
      </div>
    </van-sticky>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      loading-text="..."
      @load="onLoad"
    >
      <van-cell v-for="(item,key) in list" :key="key" class="xiax_item">
        <div class="left">
          <van-image width="2rem" height="2rem" fit="contain" :src="$imageUrl + item.image" />
        </div>
        <div class="right">
          <span class="desc">
            {{ item.title }}
            <em v-if="item.is_sell==1">(卖出)</em>
            <em v-if="item.is_sell!=1">(买入)</em>
          </span>
          <span class="money">
            订单金额：
            <em>¥{{ item.money }}</em>
          </span>
          <span class="time">完成时间：{{ item.checktime }}</span>
        </div>
      </van-cell>
    </van-list>
    <van-popup v-model="showDatePicker" position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        @cancel="onCancel"
        @confirm="onConfirm"
        @change="onChange"
      />
    </van-popup>
  </div>
</template>

<script>
import { formatDate } from "@/utils/func";
import { over_match_lists } from "@/api/goods";
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
      value1: "ALL",
      option1: [
        { text: "全部类型", value: "ALL" },
        { text: "买入订单", value: "BUY" },
        { text: "卖出订单", value: "SELL" }
      ],
      viewformatDate: "选择日期",
      showDatePicker: false,
      currentDate: new Date()
    };
  },
  watch: {},
  methods: {
    onCancel() {
      this.showDatePicker = false;
    },
    onConfirm(value) {
      this.viewformatDate = this.$func.formatDate(this.currentDate);
      this.showDatePicker = false;
      this.changeGetList();
    },
    onChange() {},
    chooeDate() {
      this.showDatePicker = true;
    },
    onChangeType(value) {
      this.value1 = value;
      this.changeGetList();
    },
    onClickLeft() {
      this.$router.push("/main/user");
    },
    changeGetList() {
      this.GetMatchLists("change");
    },
    //日志列表
    GetMatchLists(handleType = "get") {
      if (handleType == "change") {
        this.page = 1;
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      over_match_lists({
        page: this.page,
        pageSize: this.pageSize,
        type: this.value1,
        status: this.value2,
        date: this.viewformatDate == "选择日期" ? "" : this.viewformatDate
      })
        .then(response => {
          if (handleType == "change") {
            this.list = response.result;
          } else {
            this.list.push.apply(this.list, response.result);
          }
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch(error => {});
    },
    onLoad() {
      this.GetMatchLists("get");
    }
  }
};
</script>
<style lang="stylus">
.orderlistView
  .van-dropdown-menu
    height: 1rem
  .total
    display: flex
    margin: 0.2rem 0.3rem
    .date
      border-radius: 0.5rem
      font-size: 0.36rem
      height: 0.8rem
      line-height: 0.82rem
      padding: 0 0.3rem
      .transform
        transform: rotate(90deg)
  .van-list
    margin-top: 0.4rem
    .xiax_item
      span
        display: block
      .van-cell__value
        display: flex
        .left
          margin-right: 0.3rem
        .right
          color: #777
          font-size: 0.36rem
          .desc
            font-weight: 500
          .money
            em
              color: #f70101
          .time
            em
              color: #c30303
            .van-count-down
              color: #18f309
              display: inline-block
</style>
